.components-page {
  height: calc(100vh - var(--header-height));

  .table-wrapper {
    min-height: 210px;
    max-height: calc(100vh - 24rem);

    tbody > tr {
      height: 69px;
    }
  }
}

.components-table {
  :global(.p-datatable > .p-datatable-wrapper) {
    border: 1px solid #b0bbc533;
    border-radius: 12px;
  }

  :global(.p-datatable.p-datatable-scrollable > .p-datatable-wrapper) {
    border: 1px solid #b0bbc533;
    border-radius: 12px;
  }
}

.submit-ticket:hover {
  background-color: transparent !important;
}
