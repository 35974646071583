.product-tab-view {
  :global(.p-tabview-nav) {
    background-color: transparent !important;
  }

  :global(.p-tabview-nav-link) {
    background-color: transparent !important;
    padding: 1.5rem 0rem !important;
    border-width: 0px !important;
  }

  :global(.p-tabview-nav-link > .p-ink) {
    background-color: transparent !important;
  }

  :global(.p-tabview-panels) {
    background-color: transparent !important;
    padding: 1rem 0rem !important;
  }
}
