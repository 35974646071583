.product-details-tab {
  .flag-icon {
    min-width: 20px;
    aspect-ratio: 4/3;
  }

  :global(.p-treeselect .p-treeselect-label) {
    padding: 0.5rem 0.5rem;
    display: flex;
    height: 32px;
  }

  :global(.p-treeselect-label-empty) {
    min-height: calc(20px + 1rem);
    display: flex;
    line-height: 1;
  }

  :global(.p-treeselect) {
    height: 34px;
  }

  .value-text {
    color: var(--text-color-faded);
  }
}
