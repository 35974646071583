.pcf-table {
  table {
    background-color: #ffffff;
  }

  :global(.p-datatable > .p-datatable-wrapper) {
    border: 1px solid #b0bbc533;
    border-radius: 12px;
  }

  :global(.p-datatable.p-datatable-scrollable > .p-datatable-wrapper) {
    border: 1px solid #b0bbc533;
    border-radius: 12px;
  }
}
