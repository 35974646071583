.deprecated-tag {
  background-color: #ff720c26;
  color: #ff720c;
}

.draft-tag {
  background-color: #6e798c26;
  color: #6e798c;
}

.published-tag {
  background-color: #b3cb2d33;
  color: #a5bb2b;
}
