.root {
  width: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;

  @media (max-width: 1200px) {
    width: 100% !important;
  }
}

.overlay-wrapper {
  width: 400px;
  :global(.p-treenode-content) {
    padding: 0 !important;
  }
  @media (max-width: 1200px) {
    width: 100% !important;
  }
}

.label {
  height: 36px;
  display: flex;
  align-items: center;
}

.clear-icon {
  min-width: 14px;
}

:global(.p-treeselect-label-empty) {
  visibility: visible;
}

:global(.p-treeselect-label) {
  span {
    display: flex;
    align-items: center;
  }
}

:global(.p-treeselect .p-treeselect-label.p-placeholder) {
  color: #27292d;
}

:global(.p-tree .p-tree-container .p-treenode .p-treenode-content .p-checkbox) {
  visibility: hidden !important;
  margin-right: -24px;
}

.location-filter-open {
  background: #f3950033;
  color: #27292d;
  font-weight: 500;
  font-size: 1rem;
  border: none;
}

.location-filter-close {
  background: #b0bbc54d;
  color: #27292d;
  font-weight: 500;
  font-size: 1rem;
  border: none;
}

.selected-label {
  color: #f39500;
}
